// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    apiUrl: 'https://prizepop.payments.dev.zenzenzen.net',
    authTokenKey: 'access_token',
    currentSiteName: 'prizepop',
    loggerApiUrl: 'https://logger.payments.dev.zenzenzen.net',
    loggerToken: '9448eba6-ec20-4c23-b567-29d354d81d62',
    iframeToken: 'fec68ef07280ed175ee3331687ee8a73',
    siteId: 4,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
