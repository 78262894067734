import {Directive, EventEmitter, HostListener, Input, Output} from '@angular/core';

@Directive({
    selector: '[appCheckScroll]'
})
export class CheckScrollDirective {
    @Input() checkLoad: boolean;

    @Output() scrollEmit: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor() {
    }

    @HostListener('window:scroll', ['$event'])

    onScroll($event): void {
        $event.preventDefault();
        const element = document.getElementsByClassName('wrapper')[0];
        const checkScroll = (element.scrollHeight - element.scrollTop) < (element.clientHeight + 100);
        if (this.checkLoad && checkScroll) {
            this.scrollEmit.emit(true);
        }
    }
}
