<!-- Button -->
<ng-container *ngIf="user && user.id; else singInButton">
    <ng-template [ngxPermissionsOnly]="permissions.backend">
        <a [routerLink]="'/backend/contests'" [queryParams]="['isNew']">
            <button class="add-product xs:min-w-8 xs:max-w-8 mr-2"
                    mat-flat-button
                    [color]="'primary'">
                <mat-icon [svgIcon]="'add'"></mat-icon>
                <ng-container *ngIf="!isScreenSmall">
                    New Contest
                </ng-container>
            </button>
        </a>
    </ng-template>
    <button class="icon-button"
            mat-icon-button
            [matMenuTriggerFor]="userActions">
    <span class="avatar">
        <img *ngIf="showAvatar && user?.photo"
             [src]="user.photo">
        <mat-icon *ngIf="!showAvatar || !user?.photo"
                  [svgIcon]="'account_circle'"></mat-icon>
    </span>
    </button>
    <mat-menu class="user-actions-menu"
              [xPosition]="'before'"
              #userActions="matMenu">
        <button mat-menu-item>
        <span class="user-info">
            <span>Signed in as</span>
            <span class="email">{{user?.email}}</span>
        </span>
        </button>
        <mat-divider class="my-2"></mat-divider>
        <button mat-menu-item [routerLink]="profileLink">
            <mat-icon [svgIcon]="'settings'"></mat-icon>
            <span>Account details</span>
        </button>
        <ng-template [ngxPermissionsOnly]="permissions.backend">
            <ng-container *ngIf="!_storageUtilsService.isBackend">
                <mat-divider class="my-2"></mat-divider>
                <button mat-menu-item
                        [routerLink]="'/backend/dashboard'">
                    <mat-icon svgIcon="mat_outline:dashboard"></mat-icon>
                    <span>Backend Dashboard</span>
                </button>
            </ng-container>
        </ng-template>
        <ng-container *ngIf="!_storageUtilsService.isClient && user?.id">
            <mat-divider class="my-2"></mat-divider>
            <button mat-menu-item
                    routerLink="/">
                <mat-icon svgIcon="mat_outline:dashboard"></mat-icon>
                <span>Client Dashboard</span>
            </button>
        </ng-container>
        <mat-divider class="my-2"></mat-divider>
        <button mat-menu-item
                (click)="signOut()">
            <mat-icon [svgIcon]="'power_settings_new'"></mat-icon>
            <span>Sign out</span>
        </button>
    </mat-menu>
</ng-container>
<ng-template #singInButton>
         <span class="avatar">
            <a class="link flex items-center" [href]="'auth/sign-in'">
             Sign in
                <mat-icon class="ml-2"
                          [svgIcon]="'account_circle'"></mat-icon>
            </a>
        </span>
</ng-template>
