// Angular
import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-confirmation-leave-dialog',
    templateUrl: './confirmation-leave-dialog.component.html'
})
export class ConfirmationLeaveDialogComponent{

    text = 'Are you sure you want to delete? This action is irreversible.';
    btnYes = {title: 'yes', class: 'btn-green'};
    customBtn: { title: string, data: any, class: string} = null;
    btnNo = {title: 'no', class: 'btn-danger'};

    /**
     * Component constructor
     *
     * @param dialogRef: MatDialogRef<DeleteEntityDialogComponent>
     * @param data: any
     */
    constructor(
        public dialogRef: MatDialogRef<ConfirmationLeaveDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.dialogRef.disableClose = true;
        this.text = this.data?.text || this.text;
        this.btnYes = this.data?.btnYes || this.btnYes;
        this.btnNo = this.data?.btnNo || this.btnNo;
        this.customBtn = this.data?.customBtn || null;
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    onYesClick(): void {
        setTimeout(() => {
            this.dialogRef.close(true);
        }, 100);
    }

    onCustomClick(): void {
        this.dialogRef.close(this.customBtn.data);
    }
}
