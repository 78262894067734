// Angular
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';

import {ScrollToTopComponent} from './scroll-to.component';


@NgModule({
    declarations: [
        ScrollToTopComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
    ],
    exports: [
        ScrollToTopComponent
    ]
})
export class ScrollToModule {
}
