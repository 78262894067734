import {Layout} from 'app/layout/layout.types';

import {PERMISSIONS} from '../../shared/contstants/permissions.const';

// Theme type
export type Theme = 'auto' | 'dark' | 'light';

/**
 * AppConfig interface. Update this interface to strictly type your config
 * object.
 */
export interface AppConfig {
    layout: Layout;
    theme: Theme;
}

/**
 * Default configuration for the entire application. This object is used by
 * 'ConfigService' to set the default configuration.
 *
 * If you need to store global configuration for your app, you can use this
 * object to set the defaults. To access, update and reset the config, use
 * 'ConfigService'.
 */
export const appConfig: AppConfig = {
    layout: 'centered',
    theme: 'light'
};

export const backendNavigation: any[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'mat_outline:dashboard',
        link: '/backend/dashboard',
    },
    {
        id: 'users',
        title: 'Users',
        type: 'basic',
        icon: 'mat_outline:people_outline',
        link: '/backend/users',
        permission: PERMISSIONS.USERS.VIEW
    },
    {
        id: 'contests',
        title: 'Contests',
        type: 'basic',
        icon: 'mat_outline:card_giftcard',
        link: '/backend/contests'
    },
    {
        id: 'schedule',
        title: 'Schedule',
        type: 'basic',
        icon: 'mat_outline:calendar_today',
        link: '/backend/schedule'
    },
    {
        id: 'notifications',
        title: 'Notifications',
        type: 'basic',
        icon: 'mat_outline:notifications',
        link: '/backend/notifications',
        permission: PERMISSIONS.NOTIFICATIONS.VIEW
    },
    {
        id: 'wallets',
        title: 'Wallets',
        type: 'basic',
        icon: 'mat_outline:account_balance_wallet',
        link: '/backend/wallets',
    },
    {
        id: 'sponsorships',
        title: 'Sponsorship Requests',
        type: 'basic',
        icon: 'mat_outline:monetization_on',
        link: '/backend/sponsorships'
    },
    {
        id: 'roles',
        title: 'Roles',
        type: 'basic',
        icon: 'mat_outline:list',
        link: '/backend/roles',
        permission: PERMISSIONS.ROLES.VIEW
    },
    {
        id: 'configs',
        title: 'Configs',
        type: 'basic',
        icon: 'mat_outline:list',
        link: '/backend/configs',
    },
    {
        id: 'pages',
        title: 'Pages',
        type: 'basic',
        icon: 'mat_outline:sticky_note',
        link: '/backend/pages',
    },
    {
        id: 'layouts',
        title: 'Layouts',
        type: 'collapsable',
        icon: 'mat_outline:style',
        children: [
            {
                id: 'layouts-global',
                title: 'Global',
                type: 'basic',
                icon: 'mat_outline:line_style',
                link: '/backend/layouts/global',
            },
            {
                id: 'layouts-products',
                title: 'Schedule',
                type: 'basic',
                icon: 'mat_outline:calendar_today',
                link: '/backend/layouts/schedule',
            },
            {
                id: 'layouts-product',
                title: 'Contest',
                type: 'basic',
                icon: 'mat_outline:card_giftcard',
                link: '/backend/layouts/contest',
            },
            {
                id: 'layouts-wallets',
                title: 'Wallets',
                type: 'basic',
                icon: 'mat_outline:account_balance_wallet',
                link: '/backend/layouts/wallets',
            },
        ]
    },
];

export const publicNavigation: any[] = [
    {
        id: 'my-contests',
        title: 'I\'m a Contestant',
        type: 'basic',
        icon: 'mat_outline:card_giftcard',
        link: '/contests',
        exactMatch: true,
        meta: {
            isCustomer: true,
        }
    },

    {
        id: 'wallets',
        title: 'Wallets',
        type: 'basic',
        icon: 'mat_outline:account_balance_wallet',
        link: '/wallets',
        exactMatch: true,
        meta: {
            isCustomer: true,
        }
    },
    {
        id: 'schedule',
        title: 'Schedule',
        type: 'basic',
        icon: 'mat_outline:calendar_today',
        link: '/',
        exactMatch: true,
    }
];

export const gameNavigation: any[] = [
];
