import {
    Component,
    Input,
    OnInit,
    EventEmitter,
    ChangeDetectorRef,
} from '@angular/core';
import {FormUtilsService} from '../../../core/utils/form-utils.service';

import momentTz from 'moment-timezone';

@Component({
    selector: 'app-date-time-picker',
    templateUrl: './date-time-picker.component.html',
    styleUrls: ['./date-time-picker.component.scss']
})
export class DateTimePickerComponent implements OnInit {

    @Input() dateForm;
    @Input() field;
    @Input() title;
    @Input() showClearIcon = false;
    @Input() minDate;

    @Input() checkValidation = new EventEmitter();

    // необходимо чтобы заполнить время в календаре по умолчание как в Лос Анджелесе
    defaultTime = momentTz.tz(new Date(), 'America/Los_Angeles').format('HH:mm:ss').split(':');

    constructor(public _formUtilsService: FormUtilsService,
                private _changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        if (this.dateForm.get(this.field).value?.length) {
            const date = momentTz(this.dateForm.get(this.field).value);
            const timezoneDate = momentTz.tz(date, 'America/Los_Angeles').format('YYYY-MM-DD HH:mm');
            this.dateForm.get(this.field).setValue(timezoneDate);
        }
    }

    clearField(field: any): void {
        field.setValue('');
    }

    changeFormatDate(event: any): void {
        this.dateForm.get(this.field).setValue(momentTz(event.value).format('YYYY-MM-DD HH:mm'));
    }

    checkValidationPicker(): void {
        this.dateForm.get(this.field).markAsTouched();
        this.dateForm.get(this.field).updateValueAndValidity();
        this._changeDetectorRef.markForCheck();
    }

}
