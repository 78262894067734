<div class="treo-vertical-navigation-wrapper">

    <!-- Header -->
    <div class="treo-vertical-navigation-header">
        <ng-content select="[treoVerticalNavigationHeader]"></ng-content>
    </div>

    <!-- Content -->
    <div class="treo-vertical-navigation-content"
         #navigationContent>

        <!-- Content header -->
        <div class="treo-vertical-navigation-content-header">
            <ng-content select="[treoVerticalNavigationContentHeader]"></ng-content>
        </div>

        <!-- Items -->
        <ng-container *ngFor="let item of navigation; trackBy: trackByFn">

            <!-- Skip the hidden items -->
            <ng-template [ngxPermissionsOnly]="item.permission">
                <ng-container *ngIf="!item.meta">

                    <!--            &lt;!&ndash; Aside &ndash;&gt;-->
                    <!--                <treo-vertical-navigation-aside-item *ngIf="item.type === 'aside'"-->
                    <!--                                                    [item]="item"-->
                    <!--                                                    [name]="name"-->
                    <!--                                                    [activeItemId]="activeAsideItemId"-->
                    <!--                                                    [autoCollapse]="autoCollapse"-->
                    <!--                                                    [skipChildren]="true"-->
                    <!--                                                    (click)="toggleAside(item)"></treo-vertical-navigation-aside-item>-->

                    <!-- Basic -->
                    <treo-vertical-navigation-basic-item *ngIf="item.type === 'basic'"
                                                         [item]="item"
                                                         [name]="name"></treo-vertical-navigation-basic-item>

                    <!-- Collapsable -->
                    <treo-vertical-navigation-collapsable-item *ngIf="item.type === 'collapsable'"
                                                               [item]="item"
                                                               [name]="name"
                                                               [autoCollapse]="autoCollapse"></treo-vertical-navigation-collapsable-item>
                </ng-container>
            </ng-template>
            <ng-container *ngIf="item.meta && item.meta.isCustomer && isHasToken">
                <!-- Basic -->
                <treo-vertical-navigation-basic-item *ngIf="item.type === 'basic'"
                                                     [item]="item"
                                                     [name]="name"></treo-vertical-navigation-basic-item>

                <!-- Collapsable -->
                <treo-vertical-navigation-collapsable-item *ngIf="item.type === 'collapsable'"
                                                           [item]="item"
                                                           [name]="name"
                                                           [autoCollapse]="autoCollapse"></treo-vertical-navigation-collapsable-item>
            </ng-container>
        </ng-container>

        <!-- Content footer -->
        <div class="treo-vertical-navigation-content-footer">
            <ng-content select="[treoVerticalNavigationContentFooter]"></ng-content>
        </div>

    </div>

    <!-- Footer -->
    <div class="treo-vertical-navigation-footer">
        <ng-content select="[treoVerticalNavigationFooter]"></ng-content>
    </div>

</div>

<!-- Aside -->
<div class="treo-vertical-navigation-aside-wrapper"
     *ngIf="activeAsideItemId"
     [@fadeInLeft]="position === 'left'"
     [@fadeInRight]="position === 'right'"
     [@fadeOutLeft]="position === 'left'"
     [@fadeOutRight]="position === 'right'">

    <!-- Items -->
    <ng-container *ngFor="let item of navigation; trackBy: trackByFn">

        <!-- Skip the hidden items -->
        <ng-container *ngxPermissionsOnly="item.permission">

            <!-- Aside -->
            <treo-vertical-navigation-aside-item *ngIf="item.type === 'aside' && item.id === activeAsideItemId"
                                                 [item]="item"
                                                 [name]="name"
                                                 [autoCollapse]="autoCollapse"></treo-vertical-navigation-aside-item>

        </ng-container>

    </ng-container>

</div>
