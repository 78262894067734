export const COMMON: any = {
    CHANNEL_TYPES: ['mail', 'sms', 'telegram'],
    GAME_TYPES: [
        {
            id: 'blackjack',
            title: 'Blackjack'
        },
        {
            id: 'poker',
            title: 'Poker'
        },
        {
            id: 'baccarat',
            title: 'Baccarat'
        },
        {
            id: 'sports',
            title: 'Sports'
        },
        {
            id: 'slots',
            title: 'Slots'
        },
        {
            id: 'paigow',
            title: 'Pai Gow'
        },
        {
            id: 'none',
            title: 'None of the Above'
        },
    ],
    RELATIONSHIPS: [
        {
            id: 'single',
            title: 'Single',
        },
        {
            id: 'dating',
            title: 'Dating',
        },
        {
            id: 'married',
            title: 'Married',
        }
    ],
    NOTIFICATIONS: ['waiting', 'processing', 'completed', 'cancelled'],
    NOTIFICATIONS_ITEMS: ['waiting', 'processing', 'completed', 'cancelled', 'read', 'delivered', 'pending'],
    PAYMENT_STATUSES: ['failed', 'refunded', 'paid', 'processing', 'unpaid', 'declined', 'forbidden', 'cancelled', 'dispute'],
    NOTIFICATIONS_FOR_USERS: ['pending', 'processing', 'delivered', 'failed', 'cancelled', 'read'],

    SITE_SECTION: ['backend', 'control', 'agent', 'customer', 'public'],
    SITE_SECTION_CONTROL: 'control',
    SITE_SECTION_BACKEND: 'backend',
    SITE_SECTION_AGENT: 'agent',
    SITE_SECTION_CUSTOMER: 'customer',
    SITE_SECTION_PUBLIC: 'public',
    COOKIE_KEY: ['GA_config'],

    LOGO_PATCH: 'assets/images/logo/logo.png',

    PRIORITIES: [{id: 0, name: 'low'}, {id: 1, name: 'medium'}, {id: 2, name: 'high'}],

    TABLE: {
        PARAMS: {
            'created_at[orderBy]': 'desc'
        },
        PAGINATOR: {
            PAGE_SIZE: 15,
            PAGE_OPTIONS: [5, 15, 50, 100, 200, 500]
        },
        SORT: {
            DIRECTIONS: [
                {
                    id: 'asc',
                    name: 'Ascending'
                },
                {
                    id: 'desc',
                    name: 'Descending'
                }
            ]
        },
        DEFAULT_SORT: {
            ACTIVE: 'created_at',
            DIRECTION: 'desc',
        }

    }
};
