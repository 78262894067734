import {Injectable} from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate, CanActivateChild,
    Router, RouterStateSnapshot,
} from '@angular/router';
import {Observable, of} from 'rxjs';
import {NgxPermissionsService} from 'ngx-permissions';
import {switchMap} from 'rxjs/operators';

import {AuthService} from '../auth/auth.service';
import {NotificationUtilsService} from '../utils/notification-utils.service';
import {StorageUtilsService} from '../utils/storage.utils.service';

@Injectable({
    providedIn: 'root'
})
export class MainGuard implements CanActivate, CanActivateChild {

    constructor(private _ngxPermissionService: NgxPermissionsService,
                private _router: Router,
                private _notificationUtilsService: NotificationUtilsService,
                private _storageUtilsService: StorageUtilsService,
                private _authService: AuthService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this._check(state.url);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this._check(state.url);
    }

    _check(url: string): Observable<boolean> {
        return this._authService.check()
            .pipe(
                switchMap((authenticated) => {

                    // If the user is not authenticated...
                    if (!authenticated) {
                        // Redirect to the sign-in page
                        this._router.navigateByUrl('/auth/sign-in');
                        this._notificationUtilsService.showActionNotification('Please login or signup to continue', 0);

                        // Prevent the access
                        return of(false);
                    }

                    // Allow the access
                    if (url && url.length) {
                        return this.checkPermission();
                    } else {
                        of(false);
                    }
                })
            );
    }

    // проверка пермишенов, если есть то локально, если нет то запрос на их получение
    checkPermission(): Observable<boolean> {
        const permissions = this._ngxPermissionService.getPermissions();
        if (this.isEmptyObject(permissions) && this._authService._authenticated) {
            return this._authService.getProfilePermissions().pipe(
                switchMap((res: any[]) => {
                    if (!res || !res.length) {
                        this.redirectAuth();
                        return of(false);
                    } else {
                        const isAdmin = res.includes('manage roles and permissions');
                        if (isAdmin) {
                            return of(true);
                        }
                        this._router.navigateByUrl('');
                    }
                })
            );
        } else {
            return of(true);
        }
    }

    redirectAuth(): void {
        this._notificationUtilsService.showActionNotification('Please login or signup to continue', 0);
        this._router.navigateByUrl('/auth/sign-in');
        this._authService.signOut();
    }

    isEmptyObject(obj): boolean {
        for (const i in obj) {
            if (obj.hasOwnProperty(i)) {
                return false;
            }
        }
        return true;
    }
}
