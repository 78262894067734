<div class="modal-content border-0 py-3">
    <div class="modal-header border-0">
        <h4 class="modal-title mt-0">Confirmation</h4>
    </div>
    <div class="modal-body">
        <h5 class="my-0">
            {{text}}
        </h5>
    </div>
    <div class="modal-footer border-0 xs:flex-wrap">
        <button type="button" class="btn btn-rounded whitespace-pre-wrap xs:w-full xs:mx-0 xs:mb-2"
                [class]="btnNo.class"
                (click)="onNoClick()">
            {{btnNo.title}}
        </button>

        <button type="button" class="btn btn-rounded ml-2 whitespace-pre-wrap xs:w-full xs:mx-0 xs:mb-2"
                [class]="btnYes.class"
                (click)="onYesClick()">
            {{btnYes.title}}
        </button>
        <ng-container *ngIf="customBtn">
            <button type="button" class="btn btn-rounded ml-2 whitespace-pre-wrap xs:w-full xs:mx-0"
                    [class]="btnYes.class"
                    (click)="onCustomClick()">
                {{customBtn.title}}
            </button>
        </ng-container>
    </div>
</div>


