import {ElementRef, Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'phone',
})
export class PhonePipe implements PipeTransform {

    constructor(private _elementRef: ElementRef) {
    }


    transform(tel): string {
        if (!tel?.length) {
            return '';
        }
        const value = tel.toString().trim().replace(/^\+/, "");
        const cleaned = value.replace(/\D/g, '');
        const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            const intlCode = (match[1] ? '+1 ' : '');
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
        }
        return '';
    }
}
