import {Directive, ElementRef, HostBinding, Input} from '@angular/core';
import {HighlightType} from '../contstants/highlight.const';

@Directive({
    selector: '[appHighlight]'
})

export class HighlightDirective {
    @Input() appHighlight = true;

    constructor(private _elementRef: ElementRef) {
    }

    @HostBinding('style.color')
    public get color(): string {
        if (this.appHighlight) {
            return this.highlight();
        }
    }

    highlight(): any {
        let value = this._elementRef.nativeElement.innerText.trim().toLowerCase();
        let color = '#27303f';
        if (typeof value === 'number') {
            value = value ? 'yes' : 'no';
        }
        for (const field in HighlightType) {
            if (field === value) {
                color = HighlightType[field];
            }
        }
        return color;
    }
}
