import {
    backendNavigation, gameNavigation, publicNavigation,
} from './core/config/app.config';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {forkJoin, Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {environment} from '../environments/environment';
import {StorageUtilsService} from './core/utils/storage.utils.service';

import {UserService} from './layout/common/user/user.service';
import {AuthService} from './core/auth/auth.service';
import {LayoutsService} from './modules/main/services/layouts.service';
import {NgxPermissionsService} from 'ngx-permissions';
import {NotificationUtilsService} from "./core/utils/notification-utils.service";

@Injectable({
    providedIn: 'root'
})
export class InitialDataResolver implements Resolve<any> {

    /**
     * Constructor
     *
     * @param _router
     * @param _userService
     * @param _authService
     * @param _layoutsService
     * @param _notificationUtilsService
     * @param _storageUtilsService
     * @param _ngxPermissionService
     */
    constructor(
        private _router: Router,
        private _userService: UserService,
        private _authService: AuthService,
        private _layoutsService: LayoutsService,
        private _notificationUtilsService: NotificationUtilsService,
        private _storageUtilsService: StorageUtilsService,
        private _ngxPermissionService: NgxPermissionsService,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Load user
     *
     * @private
     */
    private _loadUser(): Observable<any> {
        const isUser = this._userService.user && this._userService.user.id;
        if (localStorage.getItem(environment.authTokenKey) && !isUser) {
            return this._userService.loadUser().pipe(tap(() => {
                if (this._authService.justLoggedIn) {
                    this.checkUserAvatar();
                }
            }));
        } else {
            return of(false);
        }
    }

    private getConfig(): Observable<any> {
        const configsLength = this._layoutsService.configs?.length;
        if (!configsLength) {
            return this._layoutsService.getConfig();
        } else {
            return of(true);
        }
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        if (this._authService?.hasAccessToken) {
            const permissions = this._ngxPermissionService.getPermissions();
            if (this.isEmptyObject(permissions)) {
                this._authService.getProfilePermissions().subscribe();
            }
        }
        return forkJoin([
            // User
            this._loadUser(),
            this.getConfig()
        ]).pipe(
            map(() => {
                const horizontal = route?.data?.isGame ? gameNavigation : publicNavigation;
                return {
                    navigation: {
                        default: backendNavigation,
                        horizontal
                    }
                };
            })
        );
    }

    checkUserAvatar(): void {
        const user = this._userService.user;
        if (!user?.photo?.length) {
            const message = 'To Continue You need to Upload Photo.';
            this._notificationUtilsService.showActionNotification(message, 0);
            setTimeout(() => {
                this._router.navigateByUrl('/profile');
            }, 1000);
        }
    }

    isEmptyObject(obj): boolean {
        for (const i in obj) {
            if (obj.hasOwnProperty(i)) {
                return false;
            }
        }
        return true;
    }

}
