import {NgModule, ErrorHandler} from '@angular/core';
import {BrowserModule, Title} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ExtraOptions, PreloadAllModules, RouterModule} from '@angular/router';
import {MarkdownModule} from 'ngx-markdown';
import {NgxPermissionsModule} from 'ngx-permissions';
import {TreoModule} from '@treo';
import {TreoConfigModule} from '@treo/services/config';
import {CoreModule} from 'app/core/core.module';
import {appConfig} from 'app/core/config/app.config';
import {LayoutModule} from 'app/layout/layout.module';
import {AppComponent} from 'app/app.component';
import {appRoutes} from 'app/app.routing';
import {GravatarModule} from 'ngx-gravatar';
import {WINDOW_PROVIDERS} from './core/utils/window.provider';
import { NgxMatomoTrackerModule } from '@ngx-matomo/tracker';
import { NgxMatomoRouterModule } from '@ngx-matomo/router';
import {CommonService} from './shared/services/common.service';

const routerConfig: ExtraOptions = {
    scrollPositionRestoration: 'enabled'
};

@NgModule({
    declarations: [
        AppComponent,
    ],
    providers: [
        WINDOW_PROVIDERS,
        Title,
        CommonService
    ],
    imports: [
        BrowserModule,
        NgxMatomoTrackerModule.forRoot({
            siteId: '4', // your Matomo's site ID (find it in your Matomo's settings)
            trackerUrl: '//zenanalytics.net/', // your matomo server root url
        }),
        NgxMatomoRouterModule,
        BrowserAnimationsModule,
        // Permissions
        NgxPermissionsModule.forRoot(),
        RouterModule.forRoot(appRoutes, routerConfig),

        // Treo & Treo Mock API
        TreoModule,
        TreoConfigModule.forRoot(appConfig),

        // Core
        CoreModule,

        // Layout
        LayoutModule,

        // 3rd party modules
        MarkdownModule.forRoot({}),

        GravatarModule.forRoot({})
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
