export const HighlightType = {
    yes: '#1aaa55',
    high: '#ff0000',
    low: '#71cc1d',
    per_period: '#71cc1d',
    partially_completed: '#088ce7',
    per_post: '#088ce7',
    medium: '#e68a12',
    opensea: '#e68a12',
    no: '#db3b21',
    paid: '#1aaa55',
    won: '#1aaa55',
    personal: '#1aaa55',
    processing: '#F0AD4E',
    wire: '#F0AD4E',
    unpaid: '#db3b21',
    cancelled: '#ff5fb7',
    failed: '#db3b21',
    forbidden: '#256d74',
    crypto: '#0e4ae9',
    refunded: '#f6c820',
    unresolvable: '#c36913',
    declined: '#db3b21',
    blocked: '#db3b21',
    active: '#1aaa55',
    disabled: '#db3b21',
    new: '#8026f7',
    registered: '#8026f7',
    answered: '#F0AD4E',
    seen: '#8026f7',
    sent: '#8026f7',
    dispute: '#9b00dd',
    waiting: '#8026f7',
    pending: '#8026f7',
    completed: '#1aaa55',
    created: '#1aaa55',
    delivered: '#1aaa55',
    read: '#90b5e3',
    approved: '#1aaa55',
};
