/** Angular */
import {ChangeDetectorRef, Injectable} from '@angular/core';

import {AbstractControl, FormGroup, Validators} from '@angular/forms';

import numeral from 'numeral';
import moment from 'moment';
import {isObject} from 'rxjs/internal-compatibility';
import {isBoolean} from 'lodash-es';

import _ from 'lodash';

@Injectable()
export class FormUtilsService {

    constructor() {
    }

    getErrorMessage(form: any, controlName: string, minLength: number = 3, maxlength: number = 320, minValue: number = 1, maxValue: number = 3): any {
        const control = form.controls[controlName];
        if (control.hasError('minlength')) {
            return 'Minimum field length: ' + minLength;
        }
        if (control.hasError('pattern')) {
            return 'You entered an incorrect link';
        }
        if (control.hasError('maxlength')) {
            return 'Maximum field length: ' + maxlength;
        }
        if (control.hasError('matDatetimePickerMin')) {
            return 'Stop at must be after Start at';
        }
        if (control.hasError('min')) {
            return 'The value must be greater than ' + control.errors.min.min;
        }
        if (control.hasError('max')) {
            return 'The value may not be greater than ' + control.errors.max.max;
        }
        if (control.hasError('email')) {
            return 'Not a valid email';
        }
        if (control.hasError('validatePhoneNumber')) {
            return 'Not a valid phone number';
        }
        if (control.hasError('mustMatch')) {
            return 'New password and confirm new password do not match';
        }
        if (control.hasError('serverError')) {
            return control.getError('serverError');
        }
        if (control.hasError('mustObj')) {
            return 'Please select element from list';
        }
        return control.hasError('required') ? 'This field is required' : '';
    }

    prepareServerError(response: any = null, form: FormGroup = null, cdr: ChangeDetectorRef = null, textErrors = null): any {
        if (!response || !response.error) {
            return console.log('!response.error');
        }
        let errors = response.error.errors;
        if (!errors && response.error.error && typeof response.error.error !== 'string') {
            errors = response.error.error;
        }
        if (errors) {
            Object.keys(errors).map(field => {
                let error = '';
                if (_.has(errors, field)) {
                    const values = _.get(errors, field, '');
                    if (values?.length) {
                        for (const value of values) {
                            error += value;
                        }
                    }
                }
                if (textErrors) {
                    textErrors.push(error);
                }
                if (form && form.controls[field]) {
                    form.controls[field].setErrors({'serverError': error});
                } else if (field?.length && form) {
                    const fieldObjPatch = field.replace('.', '.controls.');
                    if (_.has(form.controls, fieldObjPatch)) {
                        const control = _.get(form.controls, fieldObjPatch, '');
                        if (control) {
                            control.setErrors({'serverError': error});
                        }
                    }
                }
            });
            // Mark for check
            if (cdr) {
                cdr.markForCheck();
            }
            const message = response.error.message ? response.error.message : 'The given data was invalid.';
            return this.getMessage(message);
        } else {
            // Show the error message
            let message = 'The given data was invalid.';
            if (response.error.error && typeof response.error.error === 'string') {
                message = response.error.error;
            } else if (response.error && response.error === 'string') {
                message = response.error;
            }
            return this.getMessage(message);
        }
    }

    mustMatch(controlName: string, matchingControlName: string): any {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            const matchingControl = formGroup.controls[matchingControlName];
            if (matchingControl.errors && !matchingControl.errors.mustMatch) {
                // return if another validator has already found an error on the matchingControl
                return;
            }
            // set error on matchingControl if validation fails
            if (control.value !== matchingControl.value) {
                matchingControl.setErrors({mustMatch: true});
            } else {
                matchingControl.setErrors(null);
            }
        };
    }

    getMessage(message: string, type: string = 'error'): any {
        return {
            appearance: 'outline',
            content: message,
            shake: true,
            showIcon: false,
            type: type
        };
    }

    selectFilter(selectArray: any, value: string): string[] {
        const filterValue = this.normalizeValue(value);
        return selectArray.filter(item => {
            const itemValue = item.name ? item.name : item.label ? item.label : item;
            return this.normalizeValue(itemValue).includes(filterValue);
        });
    }

    normalizeValue(value: string): string {
        return value.toLowerCase().replace(/\s/g, '');
    }

    checkRequired(form, field): boolean {
        if (form && form.get(field) && form.get(field).validator) {
            return form.get(field).validator({} as AbstractControl) && form.get(field).validator({} as AbstractControl).required;
        }
        return false;
    }

    cleanIncludeExclude(value: string, isValidEmail: boolean = false): string {
        const emailReg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        value = this.delBlankLines(value);
        value = value.replace(/[,]{2,}/g, ',').replace(/%/g, '')
            .replace(/\r\n|\n|\r/g, ',').replace(/(^[ \t]*\n)/gm, '');
        let tempArr = value.split(',');
        if (tempArr && tempArr.length) {
            tempArr = tempArr.map(val => {
                return val.trim().replace(/\s{2,}/g, ' ').replace(/\.$/, '');
            });
            if (isValidEmail) {
                tempArr = tempArr.filter(val => {
                    return emailReg.test(val);
                });
            }
            // удаление пустых елементов
            tempArr = tempArr.filter(val => val);
            // удаление одинаковых елементов
            tempArr = [...new Set(tempArr)];
            return tempArr.join(',');
        }
        return '';
    }

    delBlankLines(text): string {
        const stringArray = text.split('\n');
        const temp = [''];
        let x = 0;
        stringArray.map(val => {
            if (!!val) {
                temp[x] = val;
                x++;
            }
        });

        return temp.join('\n');
    }

    isNumeric(n): boolean {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    validationEmail(val: string): boolean {
        const emailReg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        return emailReg.test(val);
    }

    formatMoney(val: string | number): string {
        return numeral(val).format('0,0.00');
    }

    formatDate(date: string, format?: string): string | null {
        const f = format?.length ? format : 'YYYY/MM/DD';
        if (date) {
            return moment(date).format(f);
        }
        return null;
    }

    toggleActive(val, form, fields, cdr: any, isClearField = false): void {
        const required = val && isBoolean(val) || val.checked ? Validators.required : null;
        fields.map(field => {
            if (form.controls[field]) {
                form.controls[field].setValidators(required);
                if (!required && isClearField) {
                    form.controls[field].setValue('');
                }
                form.controls[field].markAsUntouched();
                form.controls[field].updateValueAndValidity();
            }
        });
        form.updateValueAndValidity();
        cdr.markForCheck();
    }

    validation(form: any, collapse = false, collapseName = '', arrCollapse?: any[]): void {
        const controls = form.controls;
        Object.keys(controls).forEach(controlName => {
            if (controls[controlName].controls) {
                if (collapse && controls[controlName].invalid && arrCollapse) {
                    arrCollapse.push(controlName);
                }
                this.validation(controls[controlName], controlName === 'data', controlName, arrCollapse);
            }
            controls[controlName].markAsTouched();
        });
    }

    checkEmptyField(data: any, formatDate: string = 'YYYY-MM-DD HH:mm'): void {
        Object.keys(data).forEach(fieldName => {
            if (isObject(data[fieldName])) {
                this.checkEmptyField(data[fieldName]);
            } else if (!data[fieldName] && data[fieldName] !== 0 && data[fieldName] !== false) {
                delete data[fieldName];
            } else if (isBoolean(data[fieldName])) {
                data[fieldName] = data[fieldName] ? 1 : 0;
            } else if (fieldName?.length && fieldName.includes('_at')) {
                data[fieldName] = moment(data[fieldName]).format(formatDate);
            }
        });
    }

    capitalize(s): string {
        return s[0].toUpperCase() + s.slice(1);
    }

    filter(value: string | any, array: any[]): string[] {

        const filterValue = value && value.name ? value.name.toLowerCase() : value.toLowerCase();

        return array.filter(option => option.name.toLowerCase().includes(filterValue));
    }

    prepareSelectorArray(obj: any, array: any[], field = null): void {
        Object.keys(obj).map(key => {
            const item = {
                name: obj[key] + ' ' + key,
                code: key
            };
            if (field) {
                item[field] = key;
            }
            array.push(item);
        });
    }

    formatBytes(bytes, decimals = 2): string {
        if (bytes === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    dataURItoBlob(dataURI): Blob {
        const byteString = atob(dataURI.replace(/^[^,]+,/, ''));
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([int8Array], { type: 'image/jpeg' });
        return blob;
    }
}
